import * as React from "react";

import "bootstrap/dist/css/bootstrap.min.css"

import '../../sass/app.scss';

import { handleClick } from "../../utils/functions";


const Contacts = ({ data }) => {

    return (
        <div className="mt-5 left container-contactos">
            <div className="padding-5-3 bg-white">
                <h1 className="details-large gold oLight">{data.contactos.title}</h1>
                <p className=" mt-3 oMedium">
                    <a onClick={() => handleClick("tel:" + data.contactos.Mlink, 'Móvel')} className="oMedium pixel-calls">M: {data.contactos.M} <small style={{ fontStyle: 'italic' }}>«Chamada para a rede móvel nacional»</small></a>
                    <a onClick={() => handleClick("tel:" + data.contactos.Tlink, 'Fixo')} className="oMedium pixel-calls">T: {data.contactos.T} <small style={{ fontStyle: 'italic' }}>«Chamada para a rede fixa nacional»</small></a>

                </p>
            </div>

            <div className="padding-5-3 bg-white">
                <h1 className="details-large gold oLight">{data.email.title}</h1>
                <p className="d-flex mt-3 oMedium">
                    <a href={"mailto:" + data.email.email} className="oMedium">{data.email.email}</a>
                </p>
            </div>

            <div className="padding-5-3 bg-white">
                <h1 className="details-large gold oLight">{data.horario.title}</h1>
                <p className="d-flex mt-3 oMedium">
                    {data.horario.semana}<br />
                    {data.horario.sabado}
                </p>
            </div>

            <div className="padding-5-3 bg-white">
                <h1 className="details-large gold oLight">{data.morada.title}</h1>
                <a href="https://www.google.com/maps/place/UPCARE+-+Medical+Center/@40.6334875,-8.6601413,14z/data=!4m19!1m13!4m12!1m4!2m2!1d-8.634368!2d40.6454272!4e1!1m6!1m2!1s0xd23a2b1406f7a41:0x7f411ea432c1d21a!2supcare!2m2!1d-8.6531879!2d40.6204024!3m4!1s0xd23a2b1406f7a41:0x7f411ea432c1d21a!8m2!3d40.6204024!4d-8.6531879" target="_blank">
                    <p className="d-flex mt-3 oMedium">
                        {data.morada.rua},<br />
                        {data.morada.cpostal}
                    </p>
                </a>

            </div>

        </div>
    )
}

export default Contacts
