import * as React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';

import '../../sass/app.scss';
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { handleClick } from "../../utils/functions";


const ContactsMobile = ({ data }) => {
    return (
        <StyledContacts className="mt-5 left">
            <div className="padding-2-2 bg-white">
                <h1 className="details-large gold oLight">{data.contactos.title}</h1>
                <p className="d-flex mt-3 oMedium">
                    <a onClick={() => handleClick("tel:" + data.contactos.Mlink, 'Móvel')}  className="oMedium pixel-calls" rel="noreferrer">M: {data.contactos.M}</a>
                </p>
                <p className="d-flex mt-3 oMedium">
                    <a onClick={() => handleClick("tel:" + data.contactos.Tlink, 'Fixo')} className="oMedium pixel-calls" rel="noreferrer" >T: {data.contactos.T}</a>
                </p>
            </div>

            <div className="padding-2-2 bg-white">
                <h1 className="details-large gold oLight">{data.email.title}</h1>
                <p className="d-flex mt-3 oMedium">
                    <a href={"mailto:" + data.email.email} className="oMedium" rel="noreferrer" >{data.email.email}</a>
                </p>
            </div>

            <div className="padding-2-2 bg-white">
                <h1 className="details-large gold oLight">{data.horario.title}</h1>
                <p className="d-flex mt-3 oMedium">
                    {data.horario.semana}
                </p>
                <p className="d-flex mt-3 oMedium">
                    {data.horario.sabado}
                </p>
            </div>

            <div className="padding-2-2 bg-white">
                <h1 className="details-large gold oLight">{data.morada.title}</h1>
                <a rel="noreferrer" href="https://www.google.com/maps/place/UPCARE+-+Medical+Center/@40.6334875,-8.6601413,14z/data=!4m19!1m13!4m12!1m4!2m2!1d-8.634368!2d40.6454272!4e1!1m6!1m2!1s0xd23a2b1406f7a41:0x7f411ea432c1d21a!2supcare!2m2!1d-8.6531879!2d40.6204024!3m4!1s0xd23a2b1406f7a41:0x7f411ea432c1d21a!8m2!3d40.6204024!4d-8.6531879" target="_blank">
                    <p className="d-flex mt-3 oMedium">
                        {data.morada.rua}
                    </p>
                    <p className="d-flex mt-3 oMedium">
                        {data.morada.cpostal}
                    </p>
                </a>


            </div>

        </StyledContacts>
    )
}

export default ContactsMobile

const StyledContacts = styled.div`
  position: relative;
  padding: 0 6vw;
  margin-top: -50vh;
  color: #BA9E66;
  width: 100%;
  display: grid;
  gap: 3rem;
  grid-template-columns: 1.4fr 1fr;
  @media only screen and (max-width: 1090px){
    grid-template-columns: 1fr;
  }

`